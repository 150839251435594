<template>
<div class="winners-table">
  <div class="winners-table__titels">
    <div class="winners-table__title-name">Email</div>
    <div class="winners-table__title-round">Round No</div>
    <div class="winners-table__title-date">Date</div>
  </div>
  <div  class="winners-table__items">

  
    <TableItem v-for="winner in winners" :key="winner.round" :winner="winner" />
  </div>
  

    
    

</div>
</template>

 <script>
import TableItem from '@/views/winners-table/TableItem'
export default {

  props: ['winners'],
  components: {
    TableItem
  }
}
</script>

<style lang="scss" scoped>
  .winners-table {
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    margin-bottom: 20px;
    &__titels {
      color: #fff;
      margin-bottom: 14px;
      display: grid;
      grid-template-columns: 14px 1fr 80px minmax(110px, 1fr);
      column-gap: 20px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba($color: #FFBD2C, $alpha: 0.3);
      }
    }
    &__title-name {
      grid-column: 1/3;
    }
    &__title-date {
      text-align: right;
      padding-right: 30px;
    }
    &__items {
      div.table-item:nth-child(even){
        background: rgba($color: #fff, $alpha: 0.1);
        
        
      }
    }
  }
      @media (max-width: 320px) {
      .winners-table__titels {
        grid-template-columns: 14px minmax(70px, 1fr) 80px minmax(70px, 1fr);
      
        column-gap: 10px;

      }
      
  }
  
  @media (min-width: 768px) {
  .winners-table  {
    font-size: 14px;
    line-height: 16px;
  }

}

  </style>
