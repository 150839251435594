<template>
 <div class="winners">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="winners-logo">
              <img src="@/assets/img/winners-cup.png" alt="winner-cup">
              <div class="winners-logo__title">
                  {{$t('winners')}}
              </div>
              <div class="winners-logo__subtitle">
                  of previous rounds
              </div>
                <img src="@/assets/img/winners-cup.png" alt="winner-cup">
          </div>
          <WinnersTable  :winners="winners" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import WinnersTable from '@/views/winners-table/WinnersTable'
    export default {
      components: { WinnersTable },
      data() {
        return {
          winners: [],
        }
      },
      methods: {
        getParticipants() {
          this.isLoading = true
          firebase
            .firestore()
            .collection('participants')
            .get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {
                this.winners.push(doc.data())
                console.log(doc.data())
              })
              this.isLoading = false
            })
            .catch((error) => {
              this.isLoading = false
              this.$utils.showErrorMessage(error)
            })
        }
      },
      mounted() {
        this.getParticipants()
      }
    }
</script>

<style lang="scss" scoped>
    .winners-logo {
        margin-bottom: 20px;
        text-align: center;
        font-weight: 700;
        position: relative;
        display: grid;
        justify-content: center;
        align-content: center;
        grid-template-columns: 40px 160px 40px;
        grid-template-rows: 40px auto;
        column-gap: 6px;
        &__title {
            font-size: 2rem; 
            line-height: 37px;
            background: linear-gradient(310deg, #FFBD2C, #D9BB7B);
            background-clip: text; 
            -webkit-text-fill-color: transparent;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
            text-transform:uppercase;
        }
        &__subtitle {
            line-height: 19px;
            color: #FFD374;
            grid-column: 2/3;
        }
        img {
            margin-top: 3px;        
        &:last-child {
            grid-column: 3/4;
            grid-row: 1/2;
            }
        }

    }    
</style>