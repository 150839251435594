<template>
    <div  class="table-item" >
      <div :style="{backgroundImage:'url('+require('@/assets/img/winners-cup.png')+')'}" class="table-item__cup"></div>
      <div  class="table-item__name">
        <span>{{winner.email}}</span> 
        <template v-if="user.email === winner.email">
          <span class="table-item__marker">You</span> 
        </template>
        
      </div>
      <div class="table-item__round">{{winner.round}}</div>
      <div class="table-item__date">
        <time >{{convetDataDayaAndMonth}}</time> 
      </div>   
    </div>
   
  
</template>

<script>


export default ({
  data(){
    return {

    }
  },
 
  props: {
    winner: {
      type: Object
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    convetDataDayaAndMonth() {
      return this.winner.created_at.toDate().toLocaleDateString('en-US', {day: 'numeric', month: 'short'})
    }
   },
})
</script>

<style lang="scss" scoped> 
.table-item {
    display: grid;
    grid-template-columns: 14px minmax(70px, 1fr) 80px minmax(110px, 1fr);
    column-gap: 20px;
    padding: 5px 8px;
    align-items: center;
    &__cup {
      width: 14px;
      height: 18px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
 
    &__name {
      padding: 0;
      position: relative;
      span:first-child {
        display: inline-block;
        max-width: 70px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

    }
    &__round {

      font-weight: 600;
      padding-left: 22px;
    }
 
    &__marker {
      top: 50%;
      position: absolute;
      left: 77px;
      background: linear-gradient(180deg, #FFBD2C 0%, #EAC66F 100%);
      border-radius: 6px;
      color: #000;
      text-align: center;
      width: 32px;
      font-size: 10px;
      line-height: 14px;
      transform: translateY(-50%);  
    }
    &__date {
      text-align: right;
      padding-right: 9px;
    }
  

  }
    @media (max-width: 320px) {
      .table-item {
   
        grid-template-columns: 14px minmax(70px, 1fr) 80px minmax(70px, 1fr);
      
        column-gap: 10px;
        padding: 5px 5px;
  
      }
      .winners-table__titels {
        grid-template-columns: 14px minmax(70px, 1fr) 80px minmax(70px, 1fr);
      
        column-gap: 10px;

      }
      
  }
    @media (min-width: 768px) {
    .table-item{ 
      &__name {
        span:first-child {
          max-width: 120px;
        }
      }
      &__marker {
        left: 122px;
      }
      &__cup {
        width: 20px;
        height: 26px;
      }
    }
  }

</style>
